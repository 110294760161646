import React from 'react';
import { Link } from "react-router-dom";
import Navigation from '../component/Navigation';
import Title from '../component/Title';
import { getLink } from '../adapter/data';

const activeIndex = 3;

const data2 = getLink();
let l = data2.link[activeIndex];

function Contact() {
  return (
      <React.Fragment> 
        <div>       
        {/* Container */}
        <div className="container">
          {/* Header */}
        <Navigation active={activeIndex} /> 

          {/* Wrapper */}
          <div className="wrapper" >
            <Title text={l.title} link={l.to} />

            {/* Contacts Info */}
            <div className="section contacts" style={{display: 'none'}}>
              <div className="content">
                <div className="title">
                  <div className="title_inner">Contacts</div>
                </div>
                <div className="service-items">
                  <div className="service-item">
                    <div className="icon"><span className="ion ion-android-phone-portrait" /></div>
                    <div className="name">Phone</div>
                    <p>
                      + (231) 456 67 89
                    </p>
                  </div>
                  <div className="service-item">
                    <div className="icon"><span className="ion ion-email" /></div>
                    <div className="name">Email</div>
                    <p>
                      <a href="mailto:steve-pearson@gmail.com">steve-pearson@gmail.com</a>
                    </p>
                  </div>
                  <div className="service-item">
                    <div className="icon"><span className="ion ion-ios-location" /></div>
                    <div className="name">Address</div>
                    <p>
                      2621 W Pico Blvd, Los Angeles
                    </p>
                  </div>
                </div>
                <div className="contact_form">
                  <form id="cform" method="post">
                    <div className="group-val">
                      <input type="text" name="name" placeholder="Name" />
                    </div>
                    <div className="group-val">
                      <input type="text" name="email" placeholder="Email" />
                    </div>
                    <div className="group-val ct-gr">
                      <textarea name="message" placeholder="Message" defaultValue={""} />
                    </div>
                    {/* <a href="#" className="btn fill" onclick="$('#cform').submit(); return false;" data-text="Send Message">Send Message</a> */}
                  </form>
                  <div className="alert-success">
                    <p>
                      Thanks, your message is sent successfully. We will contact you shortly!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      </React.Fragment>
  );
}

export default Contact;