import React from 'react';
import { Link } from "react-router-dom";
import Navigation from '../component/Navigation';
import Loader from '../component/Loader';
import Footer from '../component/Footer';

function GenericNotFound() {
  return (
      <React.Fragment> 
    {/* Container */}
    <div className="container" >
        <Loader text="Whoops... " /> 

        {/* Wrapper */}
        <div className="wrapper">
        {/* Started */}
        <div className="section started" style={{visibility: "visible", opacity: 100}}>
            <div className="centrize full-width">
            <div className="vertical-center">
                <div className="started-content">
                <div className="h-title glitch-effect" data-text="NOT FOUND">
                    NOT <span> FOUND</span>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        <Footer></Footer>
    </div>

      </React.Fragment>
  );
}

export default GenericNotFound;
