import React from 'react';
import './App.css';
import { Link } from "react-router-dom";
import Navigation from '../component/Navigation';
import { getTitle } from '../adapter/data';

const data = getTitle();

function App() {
  return (
      <React.Fragment> 
        {/* Container */}
        <div className="container container-main">
            <Navigation /> 

            {/* Wrapper */}
            <div className="wrapper">
                {/* Started */}
                <div className="section started">
                    <div className="centrize full-width">
                    <div className="vertical-center">
                        <div className="started-content">
                            <div
                                className="h-title glitch-effect"
                                data-text={data.title1.pre +' '+ data.title1.post}
                                style={{
                                fontFamily: '"decotype-thuluth", cursive',
                                letterSpacing: "0.00em",
                                fontSize: "76px",
                                fontWeight: "normal"
                                }} >
                                {data.title1.pre}<span> {data.title1.post}</span>
                            </div>
                            <div className="h-title glitch-effect" data-text={data.title2.pre +' '+ data.title2.post}>
                                {data.title2.pre} <span>{data.title2.post}</span>
                            </div>
                            <div className="h-subtitle typing-subtitle">
                                <p>{data.desc.pre}</p>
                                <p>{data.desc.post}</p>
                            </div>
                            <span className="typed-subtitle" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </div>
      </React.Fragment>
  );
}

export default App;
