import React from 'react';
import { Link } from "react-router-dom";
import Navigation from '../component/Navigation';
import Title from '../component/Title';
import { getResume, getLink } from '../adapter/data';
import { StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Buttons from '../component/Buttons';
import BasicList from '../component/BasicList';

const activeIndex = 0;
const data = getResume();
const data2 = getLink();
let l = data2.link[activeIndex];

function Resume() {
  return (
      <React.Fragment> 
        <Navigation active={activeIndex} /> 

        {/* Container */}
        <div className="container">

            {/* Wrapper */}
            <div className="wrapper">
                <Title text={l.title} link={l.to} />

                {/* About */}
                <div className="section about">
                    <div className="content">
                        <div className="title">
                            <div className="title_inner">About Me</div>
                        </div>
                        <div className="image">
                            <img src={data2.portrait} />
                        </div>
                        <div className="desc">
                            <p dangerouslySetInnerHTML={{__html: data.desc}}></p>
                            <div className="info-list">
                                <ul>
                                    {data.info.map((e) => <li key={e.pre}>{e.pre}<strong> {e.post}</strong></li>)}
                                </ul>
                            </div>
                            
                            <Button disabled style={{ cursor: 'not-allowed', pointerEvents: 'all' }}>⬇️  Download CV</Button>
                            <div style={{ display: "none"}} className="bts">
                                <a href={data2.cv} target="_blank" className="btn fill" data-text="⬇️  View CV">
                                    ⬇️  Download CV
                                </a>
                            </div>

                        </div>
                        <div className="clear" />
                    </div>
                </div>
                
                {/* Resume */}
                <div className="section resume">
                    <div className="content">
                        <div className="cols">
                            <div className="col col-md" >

                                <div className="title"><div className="title_inner">Experience</div></div>
                                {data.exp.map((e) => 
                                    <div key={e.date} className="resume-items">
                                        <div className={(() => { 
                                            return (e.active) ? "resume-item "+"active" : "resume-item active"; 
                                        })()}>
                                            <div className="date">{e.date}</div>
                                            <div className="name">{e.title}</div>
                                            <p dangerouslySetInnerHTML={{__html: e.desc}}></p>
                                        </div>
                                    </div>
                                )}
                                <br /> 
                                <BasicList></ BasicList>
                            </div>
                            <div className="col col-md">
                                <div className="title">
                                    <div className="title_inner">Education</div>
                                </div>
                                <div className="resume-items">
                                    {data.edu.map((e) => <div key={e.title} className="resume-item">
                                        <div className="date">{e.date}</div>
                                        <div className="name">
                                            {e.title}
                                        </div>
                                        <p>{e.desc}</p>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                <StyledEngineProvider injectFirst>
                    <Buttons />
                </StyledEngineProvider>
                </div>

            </div>

        </div>
      </React.Fragment>
  );
}

export default Resume;
