import React from 'react';
import { Link } from "react-router-dom";

function Footer(props: any) {
  return (
      <React.Fragment> 
        {/* Started */}
        <div className="section started">
            <div className="centrize full-width">
                <div className="vertical-center">
                    <div className="started-content">
                        <div className="h-title glitch-effect" data-text={props.text}>
                            {props.text}
                        </div>
                        <div className="h-subtitle typing-bread">
                            <p>
                                <a href="/">🏚️</a> /{" "}
                                <a href={props.to}>{props.text}</a>
                            </p>
                        </div>
                        <span className="typed-bread" />
                    </div>
                </div>
            </div>
            <a href="#" className="mouse_btn">
                <span className="ion ion-mouse" />
            </a>
        </div>
      </React.Fragment>
  );
}

export default Footer;
