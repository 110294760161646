import React from 'react';
import { Link } from "react-router-dom";

import Navigation from '../component/Navigation';
import Title from '../component/Title';
import { getLink, getBlog } from '../adapter/data';

const activeIndex = 2;

const data2 = getLink();
let l = data2.link[activeIndex];

const data = getBlog();

function Blog() {
  return (
      <React.Fragment> 
        <div>
        {/* Container */}
        <div className="container">
          {/* Header */}
        <Navigation active={activeIndex} /> 

          {/* Wrapper */}
          <div className="wrapper">

            {/* Started */}
            <Title text={l.title} link={l.to} />

            {/* Blog */}
            <div className="section blog">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Latest Posts</div>
                </div>
                <div className="box-items">
                  
                {data.post.map((e, i) => 
                
                  <div className="box-item">
                    <div className="image">
                      <a href={e.link}>
                        <img src={e.img} alt="" />
                        <span className="info"><span className="centrize full-width"><span className="vertical-center"><span className="ion ion-ios-book-outline" /></span></span></span>
                      </a>
                    </div>
                    <div className="desc">
                      <div className="date">{e.date}</div>
                      <a href={e.link} className="name">{e.title}</a>
                    </div>
                  </div>                
                
                )}


                  <div className="box-item">
                    <div className="image">
                      <a href="https://medium.com/@safizn/theoretical-knowledge-vs-practical-application-approaches-to-learning-software-engineering-24865c1f5abb">
                        <img src="images/medium.png" alt="" />
                        <span className="info"><span className="centrize full-width"><span className="vertical-center"><span className="ion ion-ios-book-outline" /></span></span></span>
                      </a>
                    </div>
                    <div className="desc">
                      <div className="date">2020</div>
                      <a href="https://medium.com/@safizn/theoretical-knowledge-vs-practical-application-approaches-to-learning-software-engineering-24865c1f5abb" className="name">CS Self-study Key Points</a>
                    </div>
                  </div>


                </div>
                <div className="clear" />
              </div>
            </div>
          </div>

        </div>
      </div>

      </React.Fragment>
  );
}

export default Blog;
