import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './index.css';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
import Resume from "./routes/Resume";
import CV from "./routes/CV";
import Blog from "./routes/Blog";
import Post from "./routes/Post";
import Portfolio from "./routes/Portfolio";
import Contact from "./routes/Contact";
import GenericNotFound from "./routes/GenericNotFound";
import Loader from './component/Loader';
import Footer from './component/Footer';

const url = "https://drive.google.com/file/d/1SlzVsWIenW8LEexdtuKb24NhEd8XnNV-/view?usp=drive_link";
ReactDOM.render(
    <React.Fragment>
        <Loader />

        <BrowserRouter>
            <React.StrictMode>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="resume" element={<Resume />} />
                    <Route path="cv-view" element={<CV />} />
                    <Route path="/cv" element={<CV url={url} />} />
                    <Route path="blog" element={<Blog />} />
                    <Route path="post" element={<Post />} />
                    <Route path="portfolio" element={<Portfolio />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="404" element={<GenericNotFound />} />

                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </React.StrictMode>
        </BrowserRouter>

        <Footer></Footer>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
