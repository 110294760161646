import React from 'react';
import { Link } from "react-router-dom";
import Navigation from '../component/Navigation';
import Title from '../component/Title';
import { getLink } from '../adapter/data';

const activeIndex = 2;

const data2 = getLink();
let l = data2.link[activeIndex];

function Post() {
  return (
      <React.Fragment> 
        <div>
        {/* Container */}
        <div className="container">
          {/* Header */}
        <Navigation active={activeIndex} /> 

          {/* Wrapper */}
          <div className="wrapper" >
            {/* Started */}
            <Title text="CS courses @ UW-Madison" link={l.to} />
            
            {/* Blog */}
            <div className="section blog">
              <div className="content">
                <div className="single-post-text">

                  <h1>MATH240 - Intro to Discrete Mathematics:</h1>
                  <i>Logic, Proofs, Structures, Relations, Number theory, Combinatorics, Functions growth</i>
                  <p>My favorite part was the relations drawn between different representations of mathematical structures.</p>
                  <br />

                  <h1>CS300 - Programming II</h1>
                  <i>Java, Abstract data types, Data structures, Complexity analysis</i>
                  <p>Although I had prior programming experience, I found that implementing data structures was important for applying runtime analysis on algorithms. Also, leaning the programming constructs formally helped organize my understanding of the concepts.</p>
                  <br />

                  <h1>CS354 - Machine Organization & Programing</h1>
                  <i>C, x86 Assembly & Architecture, Virtual Memory & Allocator, Caching, Linux events, Async exec., Compilation</i>
                  <p>As much of my prev. practical experience was with interpreted languages, the topics covered were crucial for forming solid understanding of low-level programming languages.</p>
                  <br />

                  <h1>CS536 - Intro to PLs & Compilers</h1>
                  <i>Java, JCUP/JLex, MIPS, Compiler architecture, lexical/syntax/semantic analysis, code generation & optimization, Finite Automata</i>
                  <p>I choose this course because I’ve tried to create a graphical programming tool and wanted to have a better understanding of the code compilation process. It made me see the benefit of text-based coding, especially for applying transformations during the optimization phase.</p>
                  <br />

                  <h1>CS537 Intro to Operating Systems</h1>
                  <i>C, xv6, Virtualization, Concurrency, Persistence</i>
                  <p>Definitely a top-rated course for its importance – it should be made mandatory for every CS student. I enjoyed the book written by faculty a lot! All the magic behind hardware & software interactions/utilization was clarified.</p>
                  <br />

                  <h1>CS577 - Intro to Algorithms</h1>
                  <i>Backtracking, DP, Greedy, Divide & Conquer, Network Flow, Computational intractability, Reductions, etc.</i>
                  <p>I liked most the focus on the abstract concepts of solutions rather than the concrete implementations. Also, viewing a problem with different perspectives & the ability to transform similar problems to each other are valuable skills, as they come in handy during interviews.</p>
                  <br />

                </div>

                <div className="clear" />
              </div>
            </div>
          </div>

        </div>
      </div>

      </React.Fragment>
  );
}

export default Post;
