function getTitle() {
    return {
        title1: {
            pre: "صافي",
            post: "نصار"
        },
        title2: {
            pre: "Safi",
            post: "Nassar"
        },
        desc: {
            pre: 'Software Engineer',
            post: 'Rust, WASM, Web SaaS enthusiast.'
        },
    };
}

function getResume() {
    return {
        desc: `I've got a passion for building SaaS solutions and diving into innovative tech such as WASM. Currently, I'm investing my time mastering Rust as the primary go-to language.</br></br>             
                <i>Researched OLAP DBMS with prof. <a href="https://pages.cs.wisc.edu/~yxy/" target="_blank">Xiangyao Yu:</a></i> Extended <b>FlexPushDownDB</b> project with a shared-memory join implementation in a simulated distributed CAF system. The research paper is still in development with my colleagues.`,
        info: [
            { pre: "Safi", post: "Nassar" },
            { pre: "Software", post: "Engineering" },
            { pre: "Madison", post: ", WI" },
        ],
        edu: [
            { date: "2024", title: "MS Computer Science", desc: "University of Wisconsin-Madison" },
            { date: "2022", title: "Certificate in Computer Science (PCP) + Mathematics (VISP)", desc: "University of Wisconsin-Madison" },
            { date: "2015", title: "Dentistry (DMD)", desc: "University of Medicine & Pharmacy \"Nicolae Testemițanu\"" },
        ],
        exp: [
            { active: false, date: "2020 - 2024", title: "University Coursework", desc: "Projects mainly written in C/C++<br /> <br />" },
            { active: false, date: "~2015", title: "Personal Projects", desc: "Web apps using different technologies. <br /> React, Nodejs, Graph/Document DBs, Docker, GCP, etc. " }
        ],
        repo: [
            { title: 'Research', link: 'https://github.com/szn-cs/FlexPushdownDB' },
            { title: 'Coursework', link: 'https://github.com/orgs/szn-cs/repositories' },
            { title: 'Github Profile', link: 'https://github.com/safizn?tab=repositories&q=&type=source&language=&sort=' },
            { title: 'Abstract Program repos', link: 'https://github.com/AbstractProgram' },
            { title: 'CI/CD tools repos', link: 'https://github.com/AppScriptIO' }
        ]
    }
}

function getPortfolio() {
    return {
        category: ['Graph-Tool', 'Nodejs-WebComponents', 'Wordpress-JS-Polymer',],
        project: [

            { category: 0, link: 'https://github.com/AbstractProgram', img: '/images/program.png', title: 'Abstract Program', desc: 'Using graph data to encode chronogical steps executions.' },
            { category: 2, link: 'https://github.com/safizn/dentristWebapp', img: '/images/dentrist.jpg', title: 'Dentrist WebApp', desc: 'Preparatory platform for the dental board examination.' },
            { category: 1, link: 'https://github.com/safizn/talebWebapp', img: '/images/taleb.jpg', title: 'Taleb WebApp', desc: 'Study abroad agency.' },

        ]
    }
}

function getBlog() {
    return {
        post: [
            { img: '/images/uw-madison.jpg', link: '/post', date: 'Dec. 2021', title: 'CS courses @ UW-Madison' },
        ]
    }
}

function getLink() {
    return {
        link: [
            { to: '/resume', title: 'Resume' },
            { to: '/portfolio', title: 'Portfolio' },
            { to: '/blog', title: 'Blog' },
            // {to: '/contact', title: 'Contact'}
        ],
        cv: '#',
        portrait: 'images/portrait.jpg'
    }
}

export {
    getBlog,
    getTitle,
    getResume,
    getLink,
    getPortfolio
}
