import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { getResume, getLink } from '../adapter/data';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';

const data = getResume();

export default function BasicList() {
  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="github repositories">
        <List>

        {data.repo.map((e, i) => 
        
        <a key={e.title} target="_blank" href={e.link}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CodeSharpIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary={e.title} /> 
            </ListItemButton>
          </ListItem>
        </a>
        
        )}
          
        </List>
      </nav>
      {/* <Divider />
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="Spam" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav> */}
    </Box>
  );
}