import React from 'react';
import { Link } from "react-router-dom";

const text = "loading...";

function Loader(props: any) {
  return (
      <React.Fragment> 
        {/* Preloader */}
        <div className="preloader">
            <div className="centrize full-width">
                <div className="vertical-center">
                    <div className="pre-inner">
                        <div className="load typing-load">
                            <p>{props.text != undefined ? props.text : text}</p>
                        </div>
                        <span className="typed-load" />
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
  );
}

export default Loader;
