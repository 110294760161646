import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

const data = {
    copyright: "©"+(new Date()).getFullYear()+" Safi", 
    link: {
        linkedin: "https://www.linkedin.com/in/safizn",
        github:  "https://github.com/safizn"
    }
}

function Footer() {
  return (
      <React.Fragment> 
        {/* Footer */}
        <footer style={{ backgroundColor: "rgba(255, 255, 255, 0.45)" }}>
        <div className="soc">
            <a target="_blank" href={data.link.linkedin}>
            <span className="ion ion-social-linkedin" />
            </a>
            <a target="_blank" href={data.link.github}>
            <span className="ion ion-social-github" />
            </a>
        </div>
        <div className="copy">{data.copyright}</div>
        <div className="clr" />
        </footer>
        {/* Lines */}
        <div className="line top" />
        <div className="line bottom" />
        <div className="line left" />
        <div className="line right" />
      </React.Fragment>
  );
}

export default Footer;
