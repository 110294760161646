import React from 'react';
import { Link } from "react-router-dom";

import Navigation from '../component/Navigation';
import Title from '../component/Title';
import { getLink, getPortfolio } from '../adapter/data';

const activeIndex = 1;

const data = getPortfolio();
const data2 = getLink();
let l = data2.link[activeIndex];

function Portfolio() {
  return (
      <React.Fragment> 
        <div>

        {/* Container */}
        <div className="container">
          {/* Header */}
          <Navigation active={activeIndex} /> 

          {/* Wrapper */}
          <div className="wrapper">
            {/* Started */}
            <Title text={l.title} link={l.to} />
            
            {/* Works */}
            <div className="section works" >
              <div className="content">

                <div className="title">
                  <div className="title_inner">Recent Works</div>
                </div>


                <div className="filter-menu" >
                  <div className="filters">
                    <div className="btn-group">
                        {data.category.map((e, i) => <label key={i} data-text={e}><input type="checkbox" defaultValue={"f-"+e} />{e}</label>)} 
                    </div>
                  </div>
                </div>

                <div className="box-items" >

                    {data.project.map((e, i) => 
                    
                    <div key={i} className={"box-item f-"+data.category[e.category]}>

                        <div className="image">
                            <a target="_blank" href={"#popup-"+i} className="has-popup">
                                <img src={e.img} alt="" />
                                <span className="info"><span className="centrize full-width"><span className="vertical-center"><span className="ion ion-ios-plus-empty" /></span></span></span>
                            </a>
                        </div>

                        <div className="desc">
                            <div className="category">{data.category[e.category]}</div>
                            <a href={"#popup-"+i} className="name has-popup">{e.title}</a>
                        </div>

                        <div id={"popup-"+i} className="popup-box mfp-fade mfp-hide">
                            <div className="content">
                                <div className="image"><img src={e.img} alt="" /></div>
                                <div className="desc">
                                <div className="category">{data.category[e.category]}</div>
                                <h4>{e.title}</h4><p>{e.desc}</p>
                                <a target="_blank" href={e.link} className="btn" data-text="View Project">View Project</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    )}

                </div>
                <div className="clear" />
              </div>
            </div>
          </div>
          
        </div>
      </div>

      </React.Fragment>
  );
}

export default Portfolio;