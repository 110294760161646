import React from 'react';
import { Link } from "react-router-dom";
import { getLink } from '../adapter/data';

const data = getLink();

function Navigation(props: any) {
  return (
      <React.Fragment> 
        {/* Header */}
        <header style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
            <div className="head-top">
                <a href="#" className="menu-btn">
                <span />
                </a>
                <div className="top-menu">
                <ul>
                    {props.active != undefined ? <li><Link to="/" className="lnk">🏚️</Link></li> : ""}
                    {data.link.map((e, i) => <li key={e.title} className={(props.active==i) ? "active" : ""}><Link to={e.to} className="lnk">{e.title}</Link></li>)}
                </ul>
                </div>
            </div>
        </header>
      </React.Fragment>
  );
}

export default Navigation;
